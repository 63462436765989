import { createWebHistory, createRouter } from 'vue-router'

import Home from '../pages/Home/Home.vue'
import NotFound from '../pages/NotFound/NotFound.vue'
import profileRoute from './profileRoute.js'
import loginRoute from './loginRoute.js'
import monParcoursRoute from './monParcoursRoute.js'
import resultsRoute from './resultsRoute.js'
import gamesRoute from './gamesRoute.js'
import forgotPasswordRoute from './forgotPasswordRoute.js'
import registerRoute from './registerRoute.js'
import parametersRoute from './parametersRoute.js'


const routes = [
  loginRoute,
  profileRoute,
  monParcoursRoute,
  resultsRoute,
  ...gamesRoute,
  ...forgotPasswordRoute,
  registerRoute,
  parametersRoute,
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    // Error
    path: '/404',
    name:'NotFound',
    component:NotFound,
  },
  {
    path: "/:catchAll(.*)",
    redirect:'/404'
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'default',
    beforeEnter: (to, from, next) => {
      const userInfoString = localStorage.getItem('userInfo');
      if (userInfoString) {
        // Exclude the /django route from redirecting to the login page
        if (to.path.startsWith('/django/admin')) {
          next();
        } else {
          next();
        }
      } else {
        next('/login');
      }
    }
  }

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
