import { mapGetters } from 'vuex'
import api from '@/api';
import router from '@/router';


export default {
    name:"Navbar",
    props: {
        msg: String
    },
    data(){
    return{
        // Hide navbar if error 404
        hideNavbar: false,
        hideZoomBar: false,
        mobile_open_button:false,
        toggleProfile:false,
    };
    },
    computed: {
        ...mapGetters(['getUser', 'getToken']),
        user() {
            return this.getUser
        },
        token() {
            return this.getToken
        },
        navbarHide(){
            // Hide navbar if error 404
            if(this.$route.name === 'NotFound' || this.$route.name === 'Login' || this.$route.name === 'Register' || this.$route.name === 'gamePlay' || this.$route.name === 'ResetPassword'){
                this.hideNavbar = true;
                this.hideZoomBar = true;
            }
            else{
                this.hideNavbar = false;
                this.hideZoomBar = false;
            }
            return this.hideNavbar
        }
    },
    methods: {
    showMobilemenu() {
        this.mobile_open_button = !this.mobile_open_button;
    },
    logOut(){
        const data = {
          headers:{
              Authorization:`Token ${this.token}`
          }
      }
      api.logoutUser(data)
      .then(response => {
        console.log("Logout successfully", response)
        localStorage.removeItem('userInfo')
        router.push({ name: 'Login' }) // redirect to the login page
        location.reload();
      })
      .catch(error => {
        console.error("Failed to log out", error);
      })
      }
    },
}