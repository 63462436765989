import Button from '@/components/Form/Button.vue';
import parameterHeader from '@/components/Parameters/parameterHeader.vue';

export default {
    name: 'News',
    components: {
        Button,
        parameterHeader
    },

}
