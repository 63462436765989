<section class="flex min-h-screen flex-col-reverse sm:flex-row justify-around items-center p-6 ">
    <div class="container flex flex-col sm:flex-row justify-around  items-center p-6px-5 mx-auto my-8">
        <div class="sm:w-1/2 sm:block hidden content-center">
            <img src="@/assets/svg/logo-hosea.svg" alt="" class="h-64 mx-auto" />
        </div>
        <div class="sm:w-5/6">
            <div v-if="step1">
                <Error :errorMsg="errors.first_name" variant="large"></Error>
                <form class="text-left text-xl">
                    <hoseaInput id="first_name" name="first_name" label="Prénom" v-model="first_name" />
                    <div class="my-6"></div>

                    <Error :errorMsg="errors.last_name"></Error>
                    <hoseaInput id="last_name" name="last_name" type="last_name" label="Nom" v-model="last_name" />

                    <hoseaInput label="Genre" name="gender" type="select" :modelValue="gender" @update:modelValue="gender = $event" :options="[  { value: 'Homme', label: 'Homme' },  { value: 'Femme', label: 'Femme' },  { value: 'Plutot pas dire', label: 'Plutot pas dire' }]" v-model="gender" />
                </form>

            </div>
            <div v-if="step2">
                <Error :errorMsg="errors.age" variant="large"></Error>
                <form class="text-left text-xl">
                    <hoseaInput id="age" name="age" label="Âge" type="number" v-model="age" />
                    <div class="my-6"></div>

                    <hoseaInput label="Pathologie" name="pathology" type="select" :options="[  { value: 'Alzheimer', label: 'Alzheimer' },  { value: 'AVC', label: 'AVC' },  { value: 'Parkinson', label: 'Parkinson' }]" v-model="pathology" />
                    <div class="my-6"></div>

                    <hoseaInput label="Centres" name="centers" type="select" :options="[  { value: 'Capucins', label: 'Capucins' },  { value: 'Jalade', label: 'Jalade' },  { value: 'Plutôt pas dire', label: 'Plutôt pas dire' }]" v-model="centers" />
                    <div class="my-6"></div>

                    <hoseaInput label="Niveau education" name="education_level" type="select" :options="[  { value: '12 - 14 Years', label: '12 - 14 Years' },  { value: 'Middle', label: 'Middle' },  { value: 'High', label: 'High' }]" v-model="education_level" />


                </form>
            </div>

            <div v-if="step3">
                <Error :errorMsg="errors.email" variant="large"></Error>
                <form class="text-left text-xl">
                    <hoseaInput id="email" name="email" label="Adresse email" v-model="email" />
                    <div class="my-6"></div>

                    <Error :errorMsg="errors.password"></Error>
                    <hoseaInput id="password" name="password" type="password" label="Mot de passe" v-model="password" />
                    <div class="my-6"></div>

                    <hoseaInput id="password" name="password" type="password" label="Confirmer mot de passe" v-model="password" />

                    <div class="mt-4">
                    <div class="text-left">
                        <label class="text-gray-700 text-xl">Politique de confidentialité</label>

                    </div>
                        <div class="flex items-center mt-2">
                        <input type="checkbox" class="w-12 h-12 border-0 rounded-full default:ring-2 focus:ring-0 hover:">
                        <label for="agreement" class="ml-2 text-gray-700 text-lg text-justify">J'atteste avoir pris lu les conditions générales d'usage</label>
                        </div>

                    </div>
                </form>
            </div>
            <div class="text-right" v-if="!previousButton">
                <Button v-show="nextButton" variant="primary" size="lg" @click="nextStep">Suivant</Button>
            </div>
            <div class="flex justify-between w-full" v-else>
                <Button v-show="previousButton" variant="primary" size="lg" @click="previousStep">Retour</Button>
                <Button v-show="nextButton" variant="primary" size="lg" @click="nextStep">Suivant</Button>
                <Button v-show="finishButton" variant="primary" size="lg" @click="createUser">Terminer</Button>
            </div>
                <div class="text-center mt-8 sm:mt-0">
                <router-link to="/login" class="text-gray-700 underline hover:text-blue-700 ml-2">Se connecter</router-link>
                </div>
        </div>
    </div>
</section>