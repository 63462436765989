<template>
<div>
    <div class="div">
        <svg :height="radius * 2" :width="radius * 2">
            <circle
            stroke="black"
            :stroke-dasharray="circumference + ' ' + circumference"
            :style="{ strokeDashoffset: strokeDashoffset }"
            :stroke-width="stroke"
            fill="lightgrey"
            :r="normalizedRadius"
            :cx="radius"
            :cy="radius"
            />
            <text
            :x="radius"
            :y="radius"
            text-anchor="middle"
            dominant-baseline="middle"
            class="text-black text-2xl"
            >
            {{ progress }}%
            </text>
        </svg>

    </div>
    <h1 class="text-gray-600 text-xl">{{libelle}}</h1>

</div>
</template>

<script>
export default {
  name: 'progressRing',
  props: {
    radius: Number,
    progress: Number,
    stroke: Number,
    libelle: String,
  },
  computed: {
    strokeDashoffset() {
      return this.circumference - (this.progress / 100) * this.circumference;
    },
  },
  data() {
    const normalizedRadius = this.radius - this.stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    return {
      normalizedRadius,
      circumference,
    };
  },
};
</script>

<style scoped>
circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

</style>
