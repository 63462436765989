<parameterHeader :title="title" :icon="icon" />
<section class="flex flex-col-reverse lg:flex-row lg:px-6 items-center">
<div class="lg:container w-screen lg:w-11/12">
    <article id="parameters" class="grid grid-cols-1">
    <tableList
        v-if="!showChildComponent"
        :table-list="paramList"
        @parameter-clicked="handleParameterSelected"
    />
    <router-view v-if="showChildComponent" />
    </article>
</div>
</section>
