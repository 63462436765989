<template>
    <section class="flex flex-col px-5 mx-auto my-8 lg:flex-row lg:place-content-center" v-if="this.$store.state.user">
            <article id="categories" class="grid gap-1 md:grid-cols-2 md:gap-2 md:gap-x-12 md:gap-y-4 lg:grid-cols-4 lg:gap-4 lg:gap-x-12 lg:gap-y-4">
                <a v-for="(data,index) in dataList" v-bind:key="index" class="card border-b-8 border-gray-400 hover:shadow-lg " :class="'bg-'+dataColor" v-on:click="emitCardBtn(data)">
                    <img :src="require('@/assets/'+imageSrc)" alt="noodles" class="h-48 sm:h-52 w-full object-cover">
                        <div class="m-2 text-left text-white">
                            <label class="mr-4"><font-awesome-icon icon="search" class="text-white text-xl" /></label>
                            <span class=" text-lg font-bold">{{data.title}}</span>
                      </div>
                        <div class="badge bg-transparent">
                            <font-awesome-icon icon="question-circle" class="text-white text-2xl" />
                        </div>
                  </a>
                <!-- <Button v-for="data in dataList" class="lg:h-32" borderStyle="home" variant="primary" size="lg" @click="singleGame(data.id)">{{data.title}}
                    <svg class="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 group-hover:text-gray-700 transition-colors duration-300" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="white">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M9 5l7 7-7 7"></path>
                    </svg>
                </Button> -->
            </article>
    </section>
    <section v-else class="flex items-center h-full p-16 dark:bg-gray-900 dark:text-gray-100">
        <div class="container flex flex-col items-center justify-center px-5 mx-auto my-8">
            <div class="max-w-md text-center">
                <h2 class="mb-8 font-extrabold text-9xl dark:text-gray-600">
                    <span class="sr-only"></span><font-awesome-icon icon="sign-out"></font-awesome-icon>
                </h2>
                <p class="text-2xl font-semibold md:text-3xl">Sorry, you must be logged in to see this page.</p>
                <p class="mt-4 mb-8 dark:text-gray-400">Please log in to continue.</p>
                <router-link to="/login" class="px-8 py-3 font-semibold rounded dark:bg-violet-400 dark:text-gray-900">Log in</router-link>
            </div>
        </div>
    </section>


</template>

<script>
// import Button from '@/components/Form/Button.vue';
export default {
        name:'Card',
        components:{
            // Button
        },
        props: {
            // dataColor
            dataColor: {
                type: String,
                default: 'bg-gray-800'
            },
            // title
            dataList: {
            type: Array,
            default: () => [],
            },
            // imageSrc
            imageSrc: {
                type: String,
                default: 'pexels-pixabay-206959.jpg'
            }
        },
        methods:{
            emitCardBtn(param){
                this.$emit('CardBtn',param);
            }
        },

};

</script>