<template>
  <div>
    <!-- Your Report component content here -->
  </div>
</template>

<script>
export default {
  name: 'Report',
  // Your component logic here
}
</script>

<style scoped>
/* Your component styles here */
</style>
