<template>
  <section class="section max-w-4x1 mx-auto">
    <div id="imageHeader" class="relative">
      <img class="object-cover w-full h-36 border-b-2 border-gray-50 lg:h-48" :src="require('@/assets/'+imageSrc)">
      <div class="absolute inset-0 bg-black opacity-25"></div>
    </div>
    <div :class="headerBgColor + ' h-24'">
      <div class="container">
        <div class="flex flex-col p-4 text-left">
          <h1 class="text-3xl font-bold text-white">{{ title }}</h1>
          <p class="text-base text-white">{{ type }}</p>
        </div>
      </div>
    </div>
    <div class="container px-4 py-12 mx-auto lg:px-8">
      <h1 class="text-3xl font-bold text-left">Comment Jouer ?</h1>
      <p class="mt-8 font-thin text-gray-400 text-justify">{{ content }}</p>
      <a href="#" class="flex flex-row sm:items-center justify-start mt-8 mb-24 hover:text-red-700">
        <font-awesome-icon icon="graduation-cap" class="sm:text-2xl" />
        <label class="ml-2 text-left sm:text-center sm:text-xl font-bold gray-400">{{ scienceFact }}</label>
      </a>
        <div class="grid grid-cols-2 gap-4 sm:flex sm:flex-row sm:items-center sm:justify-between sm:mt-12">
          <div class="flex flex-row sm:items-center sm:justify-between">
            <font-awesome-icon icon="user" class="text-left text-2xl p-4 " />
            <div class="text-left">
              <div class="text-base sm:text-xl font-bold gray-400 uppercase">{{ bestScoreLabel }}</div>
              <div class="text-base sm:text-xl gray-400">{{ bestScore }}</div>
            </div>
          </div>
          <div class="flex flex-row sm:items-center sm:justify-between">
            <font-awesome-icon icon="user" class="text-left text-2xl p-4" />
            <div class="text-left">
              <label class="text-base sm:text-xl font-bold gray-400 uppercase">{{ currentLevelLabel }}</label>
              <div class="text-xl gray-400">{{ bestScore }}</div>
            </div>
          </div>
          <div class="flex flex-row sm:items-center sm:justify-between">
            <font-awesome-icon icon="user" class="text-left text-2xl p-4" />
            <div class="text-left">
            <label class="text-base sm:text-xl font-bold gray-400 uppercase">{{ difficultyLabel }}</label>
              <div class="text-xl gray-400">{{ difficulty }}</div>
            </div>
          </div>
          <div class="flex flex-row sm:items-center sm:justify-between">
            <font-awesome-icon icon="user" class="text-left text-2xl p-4" />
            <div>
            <label class="text-base sm:text-xl font-bold gray-400 uppercase">{{ difficultyLabel }}</label>
            <div class="flex flex-row justify-between">
              <Button class="h-8 w-8" shape="circle" variant="explorationvisuelle" size="xs" @click="$emit('updateLevel', level - 1)">
                <font-awesome-icon icon="minus" />
              </Button>
              <label class="text-xl  font-bold gray-400">{{ level }}</label>
              <Button class="h-8 w-8" shape="circle" variant="explorationvisuelle" size="xs" @click="$emit('updateLevel',level + 1)">
                <font-awesome-icon icon="plus" />
              </Button>

            </div>

            </div>
          </div>
          <!-- Large Screens Button -->
            <div class="hidden sm:block">
                <Button variant="explorationvisuelle" shape="primary" size="lg" @click="$emit('playGame')">
                    <label class="font-bold text-center text-xl">JOUER</label>
              </Button>
            </div>
        </div>
          <!-- Mobile Screens Button -->
            <div class="block mt-4 sm:hidden">
                <Button variant="explorationvisuelle" shape="primary" size="lg" @click="$emit('playGame')">
                    <label class="font-bold text-center text-xl">JOUER</label>
                </Button>
            </div>
           <!-- Play Button -->

    </div>
    </section>
</template>

<script>
import Button from '@/components/Form/Button.vue';
export default {
        name:'Details',
        components:{
            Button
        },
        props: {
            headerBgColor: {
                type: String,
                default: 'headerBgColor'
            },
            imageSrc: {
                type: String,
            },
            title: {
            type: String,
            default: 'Title'
            },
            type: {
            type: String,
            default: 'Type'
            },
            content: {
            type: String,
            default: 'Content'
            },
            scienceFact: {
            type: String,
            default: 'Science Fact'
            },
            bestScoreLabel: {
            type: String,
            default: 'Best Score'
            },
            bestScore: {
            type: String,
            default: '0'
            },
            currentLevelLabel: {
            type: String,
            default: 'Current Level'
            },
            currentLevel: {
            type: String,
            default: '0'
            },
            difficultyLabel: {
            type: String,
            default: 'Difficulty'
            },
            difficulty: {
            type: String,
            default: '0'
            },
            levelLabel: {
            type: String,
            default: 'Level'
            },
            level: {
            type: Number,
            default: 0
            },
        },

};

</script>