import api from '@/api';
import { mapGetters } from 'vuex'
import Button from '@/components/Form/Button.vue';
import Details from '@/components/Games/Details.vue';


export default {
  name: 'gameDetails',
  components:{
    Button,
    Details
  },
  data() {
    return {
      game: {},
      level:0,
      phaserGame:null
    };
  },
  computed: {
    ...mapGetters(['getUser', 'getToken']),
    user() {
    return this.$store.getters !== undefined ? this.getUser : null
    },
    token() {
    return this.$store.getters !== undefined ?  this.getToken : null
    },
},
  methods: {
    getGameDetails(id) {
      api.getGameDetails(id)
        .then(response => {
            this.game = response.data;
            const gameId = this.game.id;
            this.phaserGame = `/phaserGames/${gameId}-phaser/index.html`;
        })
        .catch(error => {
          console.error('Error getting game details', error);
        });
    },
    updateLevel(value){
      this.level = value
      console.log(value)
    },
    playGame() {
      this.$router.push({name:'gamePlay'});
    }

  },
  created() {
    const id = this.$route.params.id;
    this.getGameDetails(id);
  },
};
