import { createStore } from 'vuex'

const store = createStore({
  state() {
    return {
      user: null,
      token: null,
      category: null
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setToken(state, token) {
      state.token = token
    },
    setCategory(state, category){
      state.category = category
    }
  },
  actions: {
    setUser({ commit }, user) {
      commit('setUser', user)
    },
    setToken({ commit }, token) {
      commit('setToken', token)
    },
    setCategory({ commit }, category){
      commit('setCategory', category)
    }
  },
  getters: {
    getUser(state) {
      return state.user
    },
    getToken(state) {
      return state.token
    },
    getCategory(state){
      return state.category
    }
  },
})

export default store
