import api from '@/api';
export default {
    name: 'Profile',
    data() {
        return {
            userDetails:{
                age:null,
                centers:'',
                education_level:'',
                email:'',
                first_name:'',
                gender:'',
                id:null,
                last_name:'',
                pathology:'',
                username:'',
            }
        }
    },
    computed: {
    },
    methods: {
        getUserInfo(){
            const userInfoString = localStorage.getItem('userInfo');
            if (!userInfoString) {
              return;
            }

            const userInfo = JSON.parse(userInfoString);
            const user = userInfo.user;

            if (user) {
              api.getUserProfile(user)
              .then(response => {
                this.userDetails = response.data.user;
              })
              .catch(error => {
                console.error(error);
              });
            }
          }
        },
    beforeMount() {
        this.getUserInfo()
    }
};