import Games from '../pages/Games/Games.vue'
import gameDetails from '../pages/Games/gameDetails.vue'
import gamePlay from '../pages/Games/gamePlay.vue'
import categories from '../pages/Games/categories.vue'


const gamesRoute = [
    {
        path: '/categories/:id/:categoryName/games',
        name: 'Games',
        component: Games,
    },
    {
        path: '/categories',
        name:'Jeux',
        component: categories,
    },
    {
        path: '/categories/:categoryID/:categoryName/games/:id',
        name:'gameDetails',
        component: gameDetails,
    },
    {
        path: '/categories/games/:id/play',
        name:'gamePlay',
        component: gamePlay,

    }

]


export default gamesRoute;