<div v-if="!navbarHide">
  <section class="max-w-4x1 mx-auto p-4">
    <div class="container flex sm:items-center justify-end sm:justify-center">
      <button id="mobile_open_button" class="text-3xl md:hidden focus:outline-none">
        &#9776;
      </button>
      <nav class="hidden md:block md:space-x-6 md:text-2xl lg:space-x-32 lg:text-4xl font-extralight" aria-label="main navigation">
        <!-- router links :  Acceuil, Jeux, Mon parcours, Paramètres, Quitter -->
        <router-link class="text-white hover:opacity-90" to="/" exact active-class="border-b-2 border-white">Accueil</router-link>
        <router-link class="text-white hover:opacity-90" to="/categories" active-class="border-b-2 border-white">Jeux</router-link>
        <router-link class="text-white hover:opacity-90" to="/analyse" active-class="border-b-2 border-white">Mon Parcours</router-link>
        <router-link class="text-white hover:opacity-90" to="/parameters" active-class="border-b-2 border-white">Paramètres</router-link>
        <button class="text-white hover:opacity-90" @click="logOut">Quitter</button>
      </nav>
      <!-- <h1 class="text-3xl font-medium">
        Hôsea
      </h1> -->
      <div>
      </div>
    </div>
  </section>

  <section class="max-w-4x1 border-b-2 bg-gray-50 mx-auto ">
    <div class="container flex justify-between items-center">
      <div v-if="$route.name != 'Home'" class="flex items-center">
        <router-link class="text-gray-400 text-base" to="/">
          <font-awesome-icon icon="house" />
        </router-link>
        <template v-for="(route, index) in $route.matched" :key="index">
          <span class="text-gray-400 text-base mx-1">/</span>
          <router-link class="text-gray-700 text-base font-bold" :to="route.path">{{ route.name }}</router-link>
        </template>
      </div>
      <div v-else class="flex items-center">
        <font-awesome-icon class="text-gray-400 text-3xl p-1" :icon="['far','user-circle']" />
        <span class="text-gray-700 text-base mx-1">Bon retour, <span class="font-bold">{{ user.email }}</span></span>
      </div>
      <!-- BreadCrumbs -->
      <div>
        <div class="hidden lg:flex text-right">
          <button class="text-3xl font-medium w-12 h-12 border-2 border-gray-400 text-gray-400 focus:outline-none">
            &#43;
          </button>
          <button class="text-3xl font-medium w-12 h-12 border-2 border-gray-400 text-gray-400 focus:outline-none">
            &#8722;
          </button>
        </div>
      </div>
    </div>
  </section>
</div>
