<div class="section">
    <div class="container box">
        <div class="columns">
            <div class="column">
                <p class="title has-text-left">
                    Informations patient
                </p>
                <p class="subtitle has-text-left pt-6">
                    <strong>Last Name:</strong> {{userDetails.last_name}}
                </p>
                <p class="subtitle has-text-left">
                    <strong>First Name:</strong> {{userDetails.first_name}}
                </p>
                <p class="subtitle has-text-left">
                    <strong>Age:</strong> {{userDetails.age}}
                </p>
                <p class="subtitle has-text-left">
                    <strong>Gender:</strong> {{userDetails.gender}}
                </p>
                <p class="subtitle has-text-left">
                    <strong>Pathology:</strong> {{userDetails.pathology}}
                </p>
            </div>
            <div id="panelColored" class="column">
                <div class="columns">
                    <!-- Total time -->
                    <div class="column ">
                        <div id="totTime" class="card has-background-primary">
                            <div class="card-content">
                                <p class="has-text-white is-size-6"><b>Temps total</b></p>
                                <h4 id="tps_total" class="subtitle mt-2">37:26:31</h4>
                            </div>
                        </div>
                    </div>

                    <!-- Time last session -->
                    <div class="column ">
                        <div id="timeLastSession" class="card has-background-primary">
                            <div class="card-content">
                                <p class="has-text-white is-size-6"><b>Temps dernière séance</b></p>
                                <h4 id="tps_before" class="subtitle mt-2">00:00:43</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="columns">
                    <!-- Last game played -->
                    <div class="column ">
                        <div id="lastGame" class="card has-background-primary">
                            <div class="card-content">
                                <p class="has-text-white is-size-6"><b>Last Game</b></p>
                                <h4 id="last_game" class="subtitle mt-2">Fruit Ninja</h4>
                            </div>
                        </div>
                    </div>

                    <!-- Favorite game played -->
                    <div class="column ">
                        <div id="favoriteGame" class="card has-background-primary">
                            <div class="card-content">
                                <p class="has-text-white is-size-6"><b>Jeu préféré</b></p>
                                <h4 id="favorite_game" class="subtitle mt-2">Memory</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="columns mb-1">
                    <!-- Last day played -->
                    <div class="column ">
                        <div id="lastDay" class="card has-background-primary">
                            <div class="card-content">
                                <p class="has-text-white is-size-6"><b>Dernier jour</b></p>
                                <h4 id="last_time_play" class="subtitle mt-2">2023-02-24</h4>
                            </div>
                        </div>
                    </div>

                    <!-- Button help -->
                    <div class="column " id="help-card">
                        <button data-target="modal-help" class="button is-primary mt-2 modal-trigger">Aide</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>