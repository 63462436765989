<section class="min-h-screen flex flex-col-reverse sm:flex-row justify-around p-6 items-center">
    <div class="container flex flex-col sm:flex-row items-center justify-evenly px-5 mx-auto my-8">
        <div class="w-1/2 sm:block hidden">
            <img src="@/assets/svg/logo-hosea.svg" alt="" class="h-64 mx-auto" />
        </div>
        <div class="sm:w-5/6">
            <div class="my-6">
                <!-- Title : Rettrouver Mot de Passe -->
                <h1 class="text-3xl text-center sm:text-left text-gray-900">Retrouver votre mot de passe</h1>
                <!-- Lorem ipsum subtitle description -->
                <p class="mt-8 sm:mt-2 text-sm text-justify text-gray-600">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quod. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quod.</p>
            </div>
            <form class="text-center sm:text-left text-xl">
                <hoseaInput id="username" name="username" v-model="username" label="Adresse email" />
                <div class="my-6"></div>
            </form>


            <div class="flex items-center flex-col sm:flex-row justify-around">
                <Button variant="primary" size="lg">Envoyer email</Button>
                <router-link to="/login" class="p-5 text-gray-700 underline hover:text-blue-700 ml-2">Annuler</router-link>
            </div>
        </div>
    </div>
</section>
