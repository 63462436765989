<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from 'vue-chartjs'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, CategoryScale, LinearScale, BarElement)

export default {
  name: 'BarChart',
  components: {
    Bar
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },

    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: ['#A868AC', '#0087A8', '#697FAB', '#328F64', '#E74040', '#4C85A4', '#CF5D17', '#DA4E5C', '#DB4380'],
            data: [40, 20, 80, 10, 90, 50, 15, 60, 30]
          }
        ]
      },
      chartOptions: {
        responsive: true,
        plugins: {
          legend:{
            display: false
          }
        },
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
          x: {
            display: false,
            beginAtZero: true,

            grid: {
              offset: false
            },
          },
          y: {
            display: true,
            grid: {
              offset: false
            },
            suggestedMin: 0,
            suggestedMax: 100
          },
        }
      }
    }
  },
    mounted() {
    // Replace the labels with dataset data values
    this.chartData.labels = this.chartData.datasets[0].data;
  }
}
</script>
