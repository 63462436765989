<template>
  <div class="relative w-96 p-2 border-4 border-black rounded-lg">
    <div class="absolute top-0 left-0 h-full w-2/6 bg-black"></div>
    <div class="relative">
      <div class="text-lg font-bold text-left text-white">
        {{ currentStep + 1 }} / {{ steps.length }} steps
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StepTracker',
  props: {
    currentStep: {
      type: Number,
      required: true
    },
    steps: {
      type: Array,
      required: true
    }
  }
}
</script>
<style scoped>
/* add any custom styling here */
</style>