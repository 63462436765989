import api from '@/api';
import router from '@/router';
import hoseaInput from '@/components/Form/hoseaInput.vue';
import Button from '@/components/Form/Button.vue';
import Error from '@/components/Form/Error.vue';
import StepTracker from '@/components/Form/StepTracker.vue';

export default {
    name:'Register',
  components: {
    hoseaInput,
    Button,
    Error,
    StepTracker
  },
    data() {
        return {
            steps: ['Step 1', 'Step 2', 'Step 3'],
            currentStep: 0,
            previousButton: false,
            finishButton: false,
            nextButton: true,
            step1: true,
            step2: false,
            step3: false,
            age:"",
            username:"",
            email: "",
            password: "",
            first_name: "",
            last_name: "",
            gender: "",
            pathology: "",
            centers: "",
            education_level: "",
            errors:{},
        };
    },
    watch: {
        email(value){
            this.email = value;
            this.validateEmail(value);
        },
        password(value){
            this.password = value;
            this.validatePassword(value);
        },
        age(value){
            this.age = value;
            this.validateAge(value);
        },
        first_name(value){
            this.first_name = value;
            this.validateFirstName(value);
        },
        last_name(value){
            this.last_name = value;
            this.validateLastName(value);
        },
    },
    methods: {

        previousStep() {
            if (this.step2) {
              this.step2 = false;
              this.currentStep = 0;
              this.previousButton = false;
              this.finishButton = false;
              this.nextButton = true;
              this.step1 = true;
            } else if (this.step3) {
              this.step3 = false;
              this.currentStep = 1;
              this.previousButton = true;
              this.finishButton = false;
              this.nextButton = true;
              this.step2 = true;
            }
          },
          nextStep() {
            if (this.step1) {
              this.previousButton = true;
              this.nextButton = true;
              this.step1 = false;
              this.currentStep = 1;
              this.step2 = true;
            } else if (this.step2) {
              this.finishButton = true;
              this.nextButton = false;
              this.previousButton = true;
              this.step2 = false;
              this.currentStep = 2;
              this.step3 = true;
            }
          },

    createUser() {
        const user = {
            email: this.email,
            username: this.email,
            age: this.age,
            password: this.password,
            first_name: this.first_name,
            last_name: this.last_name,
            gender: this.gender,
            pathology: this.pathology,
            centers: this.centers,
            education_level: this.education_level,
        };
            api.createUser(user)
            .then(response => {
                this.errors = {};
                router.push({
                    name: 'Login',
                })
                console.log('User created:', response);
            })
            .catch(error => {
                this.errors = error.response.data
                console.error('Error creating user:', error);
            });

    },
    validateEmail(value){
        if (/^\w+([.-]?\w+)@\w+([.-]?\w+)(.\w{2,3})+$/.test(value))    {
            this.errors.email = '';
        }
        else if(value == ''){
            this.errors.email = '';
        }
        else{
            this.errors.email = ["Adresse électronique non valide"];
        }
      },
    validatePassword(value){
        if (value.length< 8) {
          this.errors.password = ["Doit contenir 8 caractères"];
        }
        else{
            this.errors.password='';
        }
        if(value == '') {
           this.errors.password = '';
        }
      },
      validateAge(value){
        if(!value){
            this.errors.age = ["Votre age est requis"]
        }
        else if (value < 1) {
          this.errors.age = ["Doit être superieur à 1"];
        }
        else{
            this.errors.age='';
        }
      },
      validateFirstName(value) {
        if (!value) {
          this.errors.first_name = ["Le prénom est requis"];
        } else if (value.length >= 30) {
          this.errors.first_name = ["Doit contenir moins de 30 caractères"];
        } else {
          this.errors.first_name = [];
        }
      },
      validateLastName(value) {
        if (!value) {
          this.errors.last_name = ["Le nom de famille est requis"];
        } else if (value.length >= 30) {
          this.errors.last_name = ["Doit contenir moins de 30 caractères"];
        } else {
          this.errors.last_name = [];
        }
      },

  }
}