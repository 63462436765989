import api from '@/api';
import { mapGetters } from 'vuex'
import Card from '@/components/Form/Card.vue';
import {normalizeString} from '@/utils';


export default {
    name:'Games',
    components: {
        Card
    },

    data(){
        return{
            user_object:{},
            gameList:[],
            gamesLoaded: false,
        }
    },
  computed: {
        ...mapGetters(['getUser', 'getToken']),
        user() {
        return this.$store.getters !== undefined ? this.getUser : null
        },
        token() {
        return this.$store.getters !== undefined ?  this.getToken : null
        },
        categoryParamId(){
            return this.$route.params.id
        },
        categoryName(){
            return this.$route.params.categoryName
        },
        filteredGameList() {
            const intId = parseInt(this.categoryParamId);
            return this.gameList.filter(game => game.idGameCategory.includes(intId));
        }
    },
    methods:{
        getGames(){
            api.getGame()
            .then(response=>{
                this.gameList = response.data;
                this.gamesLoaded = true;
                this.gameList.forEach(game => {
                    const created = new Date(game.created);
                    const formattedCreated = created.toLocaleDateString('fr-FR');
                    game.created = formattedCreated;
                  });
            })
            .catch(error =>{
                console.error('Error getting games',error);
            })
        },
        singleGame(game){
            const categoryID = encodeURIComponent(this.$route.params.id)
            const categoryName = encodeURIComponent(this.$route.params.categoryName)
            const gameToken = encodeURIComponent(game.id)
            const normalizedTitle = normalizeString(game.title);
            const encodedGameName = encodeURIComponent(normalizedTitle);
            this.$router.push({ name: 'gameDetails', params: { id: gameToken, categoryID:categoryID, categoryName:categoryName,gameName:encodedGameName } });
        }
    },
    beforeMount(){
        this.getGames()
    }
}