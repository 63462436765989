import ResetPassword from '../pages/ResetPassword/ResetPassword.vue'
import ChangePassword from '../pages/ChangePassword/ChangePassword.vue'

const forgotPasswordRoute = [
    {
        // Reset Password : Enter mail
        path: '/reset-password',
        name: 'ResetPassword',
        component:ResetPassword
    },
    {
        // ChangePassword
        path: '/change-password',
        name: 'ChangePassword',
        component:ChangePassword
    },

]

export default forgotPasswordRoute;