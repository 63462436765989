<template>
  <div>
    <label :for="name" class="text-gray-700">{{ label }}</label>
    <select v-if="hasOptions" :id="name" :value="selectedOption" @input="updateSelectedOption" class="w-full mt-2 py-4 bg-gray-100 text-gray-800 px-1 outline-none mb-4 focus:outline-none focus:border-indigo-500 shadow-md border-b-4 border border-gray-600 border-opacity-40 rounded-lg">
      <option v-for="(option, index) in options" :key="index" :value="option.value">{{ option.label }}</option>
    </select>
    <input v-else :id="name" :type="type" :autocomplete="autocomplete" class="w-full mt-2 py-4 bg-gray-100 text-gray-800 px-1 outline-none mb-4 focus:outline-none focus:border-indigo-500 shadow-md border-b-4 border border-gray-600 border-opacity-40 rounded-lg" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" />
  </div>
</template>

<script>
export default {
  name: 'hoseaInput',
  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    modelValue: {
      type: String,
      default: ''
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedOption: ''
    }
  },
  computed: {
    hasOptions() {
      return this.options.length > 0
    }
  },
  watch: {
    modelValue(value) {
      // Find the option that matches the new value and update selectedOption
      const matchingOption = this.options.find(option => option.value === value)
      this.selectedOption = matchingOption ? matchingOption.value : ''
    }
  },
  methods: {
    updateSelectedOption(event) {
      this.selectedOption = event.target.value
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>
