import Game from '@/components/Games/Game.vue';


export default {
    name: "gamePlay",
    components:{
        Game
    },
    data() {
      return {
        phaserGame: null,
      };
    },
    created() {
      const gameId = this.$route.params.id;
      this.phaserGame = `/phaserGames/${gameId}-phaser/index.html`;
      console.log(this.phaserGame)
    },
    methods:{

        returnDetails(){
            this.$router.push({name:'gameDetails'});
        }
    }
  };
