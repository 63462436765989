<template>
<!-- HTML -->
<div class="">
  <div v-if="showModal" class="fixed z-50 inset-0 overflow-y-auto">
    <div class="flex items-center justify-center min-h-screen">
      <div class="fixed inset-0 bg-gray-900 opacity-70"></div> <!-- Overlay -->
      <div class="relative w-full max-w-4xl border-b-2 p-4 bg-white rounded-lg shadow">
        <!-- Modal header -->
        <div class="flex justify-between p-5 border-b-2 border-gray-500">
          <h3 class="text-2xl uppercase font-bold text-gray-900 dark:text-white">{{title}}</h3>
          <Button class="h-6 w-6 m-1" shape="circle" variant="primary" size="xs" borderStyle="home" @click="showModal = !showModal">
            <svg aria-hidden="true" class="w-5 h-5" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            <span class="sr-only">Close modal</span>
          </Button>
        </div>
        <!-- Modal body -->
        <div class="p-6 space-y-6 sm:max-h-[400px] sm:overflow-y-auto">
          <p class="text-base text-justify leading-relaxed text-gray-500 dark:text-gray-400">{{content}}</p>
        </div>
        <div :class="showSecondButton ? 'flex justify-between' : ''">
            <Button class="mb-4" variant="primary" borderStyle="home" size="lg" @click="showModal = !showModal">{{btnText1}}</Button>
            <Button v-if="showSecondButton" class="mb-4" variant="primary" borderStyle="home" size="lg" @click="showModal = !showModal">{{btnText2}}</Button>
        </div>
        <!-- Modal footer -->
      </div>
    </div>
  </div>
    <Button variant="primary" borderStyle="primary" size="lg" @click="showModal = !showModal">{{modalButtonTxt}}</Button>
</div>
</template>


<script>
import Button from '@/components/Form/Button.vue';
export default {
    name:'popUp',
    components: {
        Button
    },
    props:{
        title: {
            type: String,
        },
        content: {
            type: String,
        },
        btnText1: {
            type: String,
            default: ''

        },
        btnText2: {
            type: String,
            default: ''
        },
        modalButtonTxt: {
            type: String,
        }
    },
    data() {
        return {
        showModal: false
        }
    },
    methods: {
        toggleModal() {
        this.showModal = !this.showModal;
        }
    },
    computed: {
    showSecondButton() {
      return this.btnText2 !== ''
    }
  }

}
</script>
