import Navbar from '../pages/Template/Navbar.vue';
import api from '@/api';

export default {
  name:"App",
  components:{
    Navbar,
  },
  computed:{
    currentRoute(){
      return this.$route.name
    }
  },
  created() {
    const userInfoString = localStorage.getItem('userInfo');
    if (!userInfoString) {
      this.$router.push({ name: 'Login' })
      return;
    }

    const userInfo = JSON.parse(userInfoString);
    const token = userInfo.token
    const user = userInfo.user
    if (token) {
      this.$store.dispatch('setUser', user)
      this.$store.dispatch('setToken', token)
      const data = {
        headers:{
            Authorization:`Token ${token}`
        }
      }
      api.checkAuth(data).then(response => {
        console.log("User is connected",response.data)
      }).catch(error => {
        console.error(error)
      });
    }
  }

}
