<template>
  <section class="flex flex-col-reverse lg:flex-row px-6">
    <div class="container flex flex-col lg:flex-row px-5 mx-auto my-8">
      <div :class="selectedCategory ? 'lg:w-5/6' : 'lg:w-full'">
        <article
          id="categories"
          class="flex flex-col lg:grid lg:grid-cols-3 lg:gap-3 lg:justify-items-center"
        >
          <Button
            v-for="(category, index) in categoryList"
            :key="index"
            class="lg:h-48 lg:w-60"
            borderStyle="home"
            :variant="getColor(category.title)"
            size="lg"
            :indicationBtnBool="true"
            @indicationBtn="showSideNav(category)"
            @mainBtn="emitGoToGames(category)"
          >
          <div class="flex flex-col">
            <label>Icon</label>
            <label>{{ category.title }}</label>
          </div>
            <svg
              class="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 group-hover:text-gray-700 transition-colors duration-300"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
              stroke="white"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M9 5l7 7-7 7"></path>
            </svg>
          </Button>
        </article>
      </div>
      <!-- Side column with title and description -->
        <div v-if="selectedCategory" class="lg:block lg:w-1/3 border-l-2 border-gray-300 ml-12 mt-6 ease-in-out duration-1000"   :class="selectedCategory ? 'translate-x-full' : 'translate-x-0'">
          <!-- Title of a category with description -->
          <article class="flex flex-col text-left ml-2">
            <div class="text-right">
              <Button class="h-6 w-6 m-1 text-right" shape="circle" variant="primary" size="xs" borderStyle="home" v-on:click="closeSideColumn">
                <svg aria-hidden="true" class="w-5 h-5" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Close modal</span>
              </Button>
            </div>
            <h2 class="text-2xl text-red-800 uppercase" :class="'text-'+getColor(selectedCategory.title)">{{ selectedCategory.title }}</h2>
            <div class="items-start">
              <p class="text-gray-600 text-justify">{{ selectedCategory.description }}</p>
            </div>
          </article>
        </div>
    </div>
  </section>
</template>
<script>
import Button from '@/components/Form/Button.vue';
import {normalizeString} from '@/utils';

export default {
  name: "CategoryList",
  props: {
    categoryList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Button
  },
  data() {
    return {
      selectedCategory: null,
    };
  },
  methods: {
    emitGoToGames(category) {
      const encodedCategoryId = encodeURIComponent(category.id);
      const normalizedTitle = normalizeString(category.title);
      const encodedCategoryName = encodeURIComponent(normalizedTitle);
      this.$emit('goToGames', encodedCategoryId, encodedCategoryName);
    },
    showSideNav(category) {
      this.selectedCategory = category;
    },
    closeSideColumn() {
      this.selectedCategory = null;
    },
    getColor(param) {
      const colorMap = {
        'Amplitude MS': 'amplitudems',
        'Coordination oeil-main': 'coordinationoeilmain',
        'Équilibre': 'equilibre',
        'Exploration visuelle': 'explorationvisuelle',
        'Mémoire': 'memoire',
        'Précision': 'precision',
        'Rapidité': 'rapidite',
        'Réflexion': 'reflexion',
        'Cause à effet': 'causeaeffet',
      };

      return colorMap[param] || '';
    },

  },
};
</script>
