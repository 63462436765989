<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'Invite',
  // Your component logic here
}
</script>

<style scoped>
/* Your component styles here */
</style>
