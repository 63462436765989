<template>
  <div v-if="displayErrorMsg" :class="['bg-red-500', 'px-3', 'py-2', 'rounded', 'text-gray-100', 'mb-3', variantClasses]">
    {{displayErrorMsg}}
  </div>
</template>

<script>
export default {
  name: "Error",
  props: {
    errorMsg: {
      type: [String, Array],
      default: '',
      required: true,
    },
    variant: {
      type: String,
      default: "default",
    },
  },
  computed: {
    variantClasses() {
      switch (this.variant) {
        case "default":
          return "";
        case "large":
          return "text-lg";
        case "small":
          return "text-sm";
        default:
          return "";
      }
    },
    displayErrorMsg() {
    if (Array.isArray(this.errorMsg)) {
        return this.errorMsg.join('');
      } else {
          return this.errorMsg;
      }
    }
  },
};
</script>

<style scoped>
/* add any custom styles here */
</style>
