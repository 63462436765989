<template>
<section class="max-w-4x1 mx-auto p-4 bg-blue-600">
    <div class="container flex justify-between items-center">
      <h1 class="text-3xl font-medium text-white">
        <div class="flex flex-col items-start">
            <label>
                <font-awesome-icon :icon="icon" class="text-white text-2xl" />
            </label>
            <label >{{title}}</label>
        </div>
        </h1>
  </div>
  </section>
</template>

<script>
export default {
    name: 'parameterHeader',
    props: {
        title: {
            type: String,
        },
        icon: {
            type: String,
        }
    },
    data() {
        return {
        }
    },
    methods: {
    },
    computed: {
    },
    mounted() {
    }

}
</script>

<style>

</style>