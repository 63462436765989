<template>
  <button :class="classes">
    <div v-if="indicationBtnBool">
      <div class="absolute top-0 left-0 flex items-center space-x-2 ml-2 mt-2">
        <button class="text-white text-xl bg-transparent border-none cursor-pointer">
          <font-awesome-icon icon="question-circle" v-on:click="$emit('indicationBtn')"/>
        </button>
      </div>
      <div class="div" v-on:click="$emit('mainBtn')">
        <slot></slot>
      </div>
        </div>
    <div v-else>
        <slot></slot>
    </div>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    type: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    borderStyle:{
        type: String,
        default: 'primary'
    },
    variant: {
      type: String,
      default: 'primary'
    },
    shape: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'md'
    },
    indicationBtnBool:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      return [
        'text-gray-100',
        {
            'border-b-4 border-gray-600 border-opacity-40 hover:bg-blue-600': this.borderStyle === 'primary',
            'border-b-4 border-gray-600 border-opacity-40 hover:opacity-80': this.borderStyle === 'secondary',
            'border border-gray-500 border-opacity-40 hover:opacity-60': this.borderStyle === 'parameters',
            'border-2 shadow-lg border-white shadow-black-500 hover:opacity-80 transform hover:scale-105 hover:text-gray-200 transition duration-200': this.borderStyle === 'home'
        },
        {
          'px-8 py-4 rounded-lg': this.shape === 'primary',
          'rounded-full': this.shape === 'circle',
          // Square
          'px-8 py-4 rounded-none': this.shape === 'square',
        },
        'transition-colors',
        {
          'bg-blue-500': this.variant === 'primary',
          'bg-white text-gray-900': this.variant === 'white',
          'bg-gray-300 text-gray-600': this.variant === 'secondary',
        // 9 Copies of the same code
            'bg-amplitudems': this.variant === 'amplitudems',
            'bg-causeaeffet': this.variant === 'causeaeffet',
            'bg-coordinationoeilmain': this.variant === 'coordinationoeilmain',
            'bg-equilibre': this.variant === 'equilibre',
            'bg-explorationvisuelle': this.variant === 'explorationvisuelle',
            'bg-memoire': this.variant === 'memoire',
            'bg-precision': this.variant === 'precision',
            'bg-rapidite': this.variant === 'rapidite',
            'bg-reflexion': this.variant === 'reflexion',
          'text-gray-500': this.disabled
        },
        {
          'text-xs': this.size === 'xs',
          'text-sm': this.size === 'sm',
          'text-base': this.size === 'md',
          'text-lg': this.size === 'lg'
        }
      ]
    }
  },
  methods: {

  }
}
</script>
