import Button from '@/components/Form/Button.vue';
import parameterHeader from '@/components/Parameters/parameterHeader.vue';

export default {
    name: 'Profil',
    components: {
        Button,
        parameterHeader
    },
// TODO:CRUD of Profile
/**
 * 1. Update the profile
 * 2. Delete the profile
 * 3. Get the profile
*/
}
