<template>
  <section class="section mt-8">
  <div class="container flex flex-col">
    <hoseaInput label="Langue" class="text-left text-2xl" name="education_level" type="select" :options="languages" />

  </div>
  </section>
</template>

<script>
import hoseaInput from '@/components/Form/hoseaInput.vue'
export default {
  name: 'Language',
  components: {
    hoseaInput
  },
  data(){
    return{
      languages:[
        // Languages
        {
          value: 'English',
          label: 'English'
        },
        {
          value:'French',
          label:'French'
        }
      ]
    }
  }
  // Your component logic here
}
</script>

<style scoped>
/* Your component styles here */
</style>
