<section class="mt-4 sm:mt-0 p-8 sm:p-0">
  <div class="container">
    <div class="flex flex-col md:flex-row justify-between">
      <div class="grid grid-cols-2 gap-4 sm:w-1/2">
        <div id="calendarCard" class="bg-white border border-b-4 border-gray-400 rounded-lg overflow-auto shadow mt-8 lg:mt-0">
          <div class="bg-gray-200 border-gray-400 border-b-2 rounded-t-lg shadow">
            <!-- Date -->
            <h2 class="text-md text-left p-2 tracking-tight text-black font-semibold">Calendrier:</h2>
          </div>
          <div class="p-4">
            <div class="grid grid-cols-7">
              <div v-for="day in days" :key="day" class="flex items-center justify-center">
                <p class="text-sm font-semibold">{{ day }}</p>
              </div>
            </div>
            <div class="grid grid-cols-1 mt-2">
              <div v-for="row in 4" :key="row" class="grid grid-cols-7 ">
                <div v-for="col in 7" :key="col" class="flex items-center justify-center">
                  <div class="flex items-center mt-2">
                    <input type="checkbox" class="w-4 h-4 border-0 rounded-full default:ring-2 focus:ring-0 hover:">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="timeGame" class="bg-white border border-b-4 border-gray-400 rounded-lg shadow mt-8 lg:mt-0" >
          <div class="bg-gray-200 border-gray-400 border-b-2 rounded-t-lg shadow">
              <!-- Date -->
              <h2 class="text-md text-left p-2 tracking-tight text-black font-semibold ">Temps de jeu :</h2>
          </div>
            <LineChart class="h-48"/>
        </div>
          <div id="courseInfo" class="bg-white border border-b-4 border-gray-400 rounded-lg shadow col-span-2" >
            <div class="bg-gray-200 border-gray-400 border-b-2 rounded-t-lg shadow">
              <!-- Date -->
              <h2 class="text-md text-left p-2 text-black font-semibold tracking-tight ">Parcours</h2>
            </div>
            <barChart/>
            </div>
            <div id="totalTime" class="bg-white text-black border border-b-4 border-gray-400 rounded-lg shadow max-h-20 flex flex-col items-start justify-center">
              <div class="p-4">
                <h2 class="mb-1 text-xl font-extrabold tracking-tight">Temps total :</h2>
                <p class="text-sm text-left">06:34:25</p>
              </div>
          </div>
              <div id="favoriteGame" class="bg-blue-600 text-white border border-b-4 border-gray-400 rounded-lg shadow max-h-20 flex flex-col items-start justify-center">
                <div class="p-4">
                  <h2 class="mb-1 text-xl font-extrabold tracking-tight">Jeu préféré:</h2>
                  <p class="text-sm text-left">Balloon Pop</p>
                </div>
            </div>
          </div>
      <analyseCard/>
    </div>
  </div>
</section>
