export const dataList = [
    {
      name: 'General',
      path: 'General',
      icon: 'gear',
      parameters: [
        {
            name: 'Langue',
            path: 'Language',
            icon: 'lock',
        },
        {
            name: 'Son interface',
            path: 'SoundInterface',
            icon: 'lock',
        },
        {
            name: 'Effets sonores',
            path: 'SoundEffects',
            icon: 'lock',
            },
        {
            name: 'Partager',
            path: 'Share',
            // Upload symbole
            icon: 'upload',
        },
        {
            name: 'Inviter un ami',
            path: 'Invite',
            icon: 'user',
        },
        {
            name: 'Noter application',
            path: 'Rate',
            icon: 'user',
        },
        {
            name: 'Signaler un bug',
            path: 'Report',
            icon: 'user',
        },
      ],
    },
    {
        name:'Accessibilité',
        path: 'Accessibility',
        icon:'lock'
    },
    {
        name:'Contact',
        path:'Contact',
        icon:'phone'
    },
    {
        name:'Juridique',
        path: 'Legal',
        icon:'shield-alt'
    },
    {
        name:'Abonnement',
        path: 'Subscription',
        icon:'credit-card'
    },
    {
        name:'News',
        path: 'News',
        icon:'newspaper'
    },
    {
        name:'Notification',
        path: 'Notification',
        icon:'bell'
    },
    {
        name:'Profil',
        path: 'Profil',
        icon:'user'
    }
  ];
