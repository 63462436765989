import Button from '@/components/Form/Button.vue';
import analyseCard from '@/components/Analyse/analyseCard.vue';
import LineChart from '@/components/Graph/LineChart.vue';
import barChart from '@/components/Graph/barChart.vue';

export default {
    name:'Analyse',
    components: {
        Button,
        analyseCard,
        LineChart,
        barChart,
    },
    data(){
        return{
            days: ["L", "M", "M", "J", "V", "S", "D"],
        }
    },
    beforeMount(){
        // We are on which page
        console.log("Analyse", this.$route.name)
    }

}