import axios from 'axios';

const api = axios.create({
  baseURL: 'https://hosea2.kara.technology/django/',
  withCredentials: true,
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'csrftoken',
});

export default {
  createUser(data) {
    return api.post('api/create_user/', data);
  },
  loginUser(data) {
    return api.post('api/login/', data);
  },
  logoutUser(data){
    return api.get('api/logout/', data);
  },
  checkAuth(data) {
    return api.get('api/check_auth/',data);
  },
  getGame(){
    return api.get('api/games')
  },
  getGameDetails(data){
    return api.get(`api/games/${data}`)
  },
  getUserProfile(data){
    return api.post('api/user/',data)
  },
  getCategories(){
    return api.get('api/game-categories/')
  },
  getCategoriesDetails(data){
    return api.get('api/game-categories/',data)
  }
};
