<template>
      <div id="personalizedProgramCard" class=" border border-b-4 border-gray-400 rounded-lg sm:ml-4 sm:w-5/6">
        <div class="bg-white border-white rounded-t-lg shadow">

            <div class="sm:hidden">
                <label for="tabs" class="sr-only">Select your country</label>
                <select id="tabs" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option>Profile</option>
                    <option>Canada</option>
                    <option>France</option>
                    <option>Germany</option>
                </select>
            </div>
            <ul class="hidden text-sm font-medium text-center text-gray-500 divide-x divide-gray-200 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
                <li class="w-full">
                    <a href="#" class="inline-block w-full p-4 text-gray-900 bg-gray-100 rounded-l-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none dark:bg-gray-700 dark:text-white" aria-current="page">CÀE</a>
                </li>
                <li class="w-full">
                    <a href="#" class="inline-block w-full p-4 text-gray-900 bg-white rounded-l-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none dark:bg-gray-700 dark:text-white" aria-current="page">REF</a>
                </li>
                <li class="w-full">
                    <a href="#" class="inline-block w-full p-4 text-gray-900 bg-white rounded-l-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none dark:bg-gray-700 dark:text-white" aria-current="page">RAP</a>
                </li>
                <li class="w-full">
                    <a href="#" class="inline-block w-full p-4 text-gray-900 bg-white rounded-l-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none dark:bg-gray-700 dark:text-white" aria-current="page">PRÉ</a>
                </li>
                <li class="w-full">
                    <a href="#" class="inline-block w-full p-4 text-gray-900 bg-white rounded-l-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none dark:bg-gray-700 dark:text-white" aria-current="page">MM</a>
                </li>
                <li class="w-full">
                    <a href="#" class="inline-block w-full p-4 text-gray-900 bg-white rounded-l-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none dark:bg-gray-700 dark:text-white" aria-current="page">EV</a>
                </li>
                <li class="w-full">
                    <a href="#" class="inline-block w-full p-4 text-gray-900 bg-white rounded-l-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none dark:bg-gray-700 dark:text-white" aria-current="page">EQUI</a>
                </li>
                <li class="w-full">
                    <a href="#" class="inline-block w-full p-4 text-gray-900 bg-white rounded-l-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none dark:bg-gray-700 dark:text-white" aria-current="page">COM</a>
                </li>
                <li class="w-full">
                    <a href="#" class="inline-block w-full p-4 text-gray-900 bg-white rounded-l-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none dark:bg-gray-700 dark:text-white" aria-current="page">AMS</a>
                </li>
            </ul>
       </div>
        <div class="p-4 rounded-lg md:p-8" id="services" role="tabpanel" aria-labelledby="services-tab">
            <div class="flex flex-row">
                <div class="flex flex-col w-1/2 mr-2">
                    <div id="parcours" class="flex flex-col h-1/3">
                        <h1 class="text-2xl text-left">
                            Parcours :
                        </h1>
                    </div>
                    <div id="score" class="flex flex-col mb-8">
                        <div class="flex items-center justify-between">
                            <h1 class="text-2xl">
                                Score :
                            </h1>
                            <h1 class="text-2xl">
                                50
                            </h1>
                        </div>
                            <div class="w-full h-9 bg-white rounded-full border-2 border-gray-700">
                                <div class="h-full bg-reflexion rounded-full" style="width: 50%"></div>
                            </div>
                    </div>
                    <div id="centile" class="flex flex-col">
                        <h1 class="text-2xl text-left">
                            Centile :
                        </h1>
                        <div class="flex flex-row">
                            <progress-ring :radius="circle.radius" :progress="circle.progress" :stroke="circle.stroke" libelle="Test 1"></progress-ring>
                            <progress-ring :radius="circle.radius" :progress="circle.progress" :stroke="circle.stroke" libelle="Test 2"></progress-ring>
                            <progress-ring :radius="circle.radius" :progress="circle.progress" :stroke="circle.stroke" libelle="Test 3"></progress-ring>
                        </div>
                    </div>
                </div>
                <div class="border-l-2 flex flex-col w-1/2">
                    <div id="results" class="ml-2">
                        <h1 class="text-2xl text-left">Résultats :</h1>
                        <div class="flex flex-row">
                            <radarChart></radarChart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      </div>
</template>

<script>
import progressRing from '@/components/Others/progressRing.vue'
// import PieChart from '@/components/Graph/PieChart.vue';
import radarChart from '@/components/Graph/radarChart.vue'



export default {
    name:'analyseCard',
    components:{
        'progress-ring':progressRing,
        // PieChart,
        radarChart
    },
    data(){
        return{
            circle:{
                stroke: 6,
                radius: 70,
                progress: 40,
            },
        }
    },
    methods:{
        // setProgress(event) {
        //     const value = parseInt(event.target.value, 10);
        //     if (!isNaN(value) && value >= 0 && value <= 100) {
        //         this.progress = value;
        //     }
        // },

    }

}
</script>

<style scoped lang="scss">
</style>