<template>
<div>
  <section class="max-w-4x1 border-b-2 bg-gray-50 mx-auto p-8">
    <div class="flex flex-row justify-around">
        <!-- Seperated in two, first div with Level, Score, Life and Time, the second with 3 buttons: Quit, Replay, and help -->
          <div class="hidden sm:flex sm:justify-center sm:items-center space-x-12">
            <div class="flex flex-col">
              <h1 class="font-bold text-xl">NIVEAU</h1>
              <p>4 / 100</p>
            </div>
              <div class="flex flex-col">
                <h1 class="font-bold text-xl">SCORE</h1>
                <p>4344</p>
            </div>
              <div class="flex flex-col">
                <h1 class="font-bold text-xl">VIE</h1>
                <p>10/15</p>
            </div>
              <div class="flex flex-col">
                <h1 class="font-bold text-xl">TEMPS</h1>
                <p>01:56</p>
            </div>
          </div>
          <div class="flex flex-row justify-between space-x-4 sm:space-x-12">
            <!-- 3 Buttons, Quitter, Rejouer et Aide -->
              <Button type="button" variant="primary" shape="primary" size="md" borderStyle="primary" @click="$emit('gameDetails')">Quitter</Button>
              <Button type="button" variant="primary" shape="primary" size="md" borderStyle="primary">Rejouer</Button>
            <popUp
                title="Comment jouer ?"
                content="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut, ullam. Eius maxime facere vitae neque consectetur itaque, culpa nostrum sint aperiam, alias veritatis hic, libero architecto animi fugit excepturi nulla."
                btnText1="Compris"
                modalButtonTxt="Aide"
            />
          </div>
    </div>
  </section>
      <div class="aspect-video">
        <iframe :src="phaserGame" class="mx-auto w-full" height="800" frameborder="0"></iframe>
      </div>
</div>
</template>

<script>
import Button from '@/components/Form/Button.vue';
import popUp from '@/components/Form/popUp.vue';
export default {
    name: "Game",
    components: {
        Button,
        popUp
    },
    props:{
        phaserGame: {
            type: String,
        }
    },
}

</script>