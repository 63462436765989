import tableList from '@/components/Others/tableList.vue';
import {dataList} from '@/pages/Parameters/dataList.js';
import parameterHeader from '@/components/Parameters/parameterHeader.vue';

export default {
  name: 'Parameters',
  components: {
    tableList,
    parameterHeader,
  },
  data() {
    return {
        title: 'Parameter',
        icon: 'gear',
        'paramList':dataList,
        showChildComponent: false,
    };
  },
  mounted() {
    const currentRoute = this.$router.currentRoute;
    if (currentRoute.name && currentRoute.name === 'Parameters') {
      console.log("Parameters", currentRoute.name);
    }
  },
  methods: {
    handleParameterSelected(parameter) {
      this.title = parameter.name;
      this.icon = parameter.icon;

      if (parameter.path === 'General') {
        this.paramList = parameter.parameters;
        this.showChildComponent = false;
      } else {
        this.showChildComponent = true;
      }
    },
  },
};