// Script Vue js

import hoseaInput from '@/components/Form/hoseaInput.vue';
import Button from '@/components/Form/Button.vue';

export default {
    components: {
        hoseaInput,
        Button,

    },
    data() {
        return{
            username:"",
        }
    },

}