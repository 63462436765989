import CategoryList from '@/components/Games/CategoryList.vue';
import api from '@/api';

export default {
  name: 'categories',
  components: {
    CategoryList,
  },
  data() {
    return {
        categoryList: [],
        categoryListLoaded: false,
    };
  },
  methods: {
    getCategories() {
      api.getCategories()
        .then((response) => {
            this.categoryList = response.data;
            this.categoryListLoaded = true;
        })
        .catch((error) => {
          console.error('Error getting categories', error);
        });
    },
    goToGames(id, categoryName){
        const categoryId = encodeURIComponent(id)
        this.$router.push({ name: 'Games',params: {id: categoryId, categoryName: categoryName } });
    },
  },
  beforeMount() {
      this.getCategories();
  }
};
