import Parameters from '../pages/Parameters/parameter.vue'
import General from '../pages/Parameters/parameterOptions/General/General.vue'
import Accessibility from '../pages/Parameters/parameterOptions/Accessibility/Accessibility.vue'
import Contact from '../pages/Parameters/parameterOptions/Contact/Contact.vue'
import Legal from '../pages/Parameters/parameterOptions/Legal/Legal.vue'
import News from '../pages/Parameters/parameterOptions/News/News.vue'
import Notification from '../pages/Parameters/parameterOptions/Notification/Notification.vue'
import Profil from '../pages/Parameters/parameterOptions/Profil/Profil.vue'
import Subscription from '../pages/Parameters/parameterOptions/Subscription/Subscription.vue'

// General
import Language from '../pages/Parameters/parameterOptions/General/generalOptions/Language.vue'
import SoundInterface from '../pages/Parameters/parameterOptions/General/generalOptions/SoundInterface.vue'
import SoundEffects from '../pages/Parameters/parameterOptions/General/generalOptions/SoundEffects.vue'
import Share from '../pages/Parameters/parameterOptions/General/generalOptions/Share.vue'
import Invite from '../pages/Parameters/parameterOptions/General/generalOptions/Invite.vue'
import Rate from '../pages/Parameters/parameterOptions/General/generalOptions/Rate.vue'
import Report from '../pages/Parameters/parameterOptions/General/generalOptions/Report.vue'



const parametersRoute ={
    path: '/parameters',
    name: 'Parameters',
    component: Parameters,
    children: [
      {
        path: 'general',
        name: 'General',
        component: General,
        children: [
          {
            path: 'language',
            name: 'Language',
            component: Language,
            meta: {
              childComponent: true
            }
          },
          {
            path: 'soundInterface',
            name: 'SoundInterface',
            component: SoundInterface,
            meta: {
              childComponent: true
            }
          },
          {
            path: 'soundEffects',
            name: 'SoundEffects',
            component: SoundEffects,
            meta: {
              childComponent: true
            }
          },
          {
            path: 'share',
            name: 'Share',
            component: Share,
            meta: {
              childComponent: true
            }
          },
          {
            path: 'invite',
            name: 'Invite',
            component: Invite,
            meta: {
              childComponent: true
            }
          },
          {
            path: 'rate',
            name: 'Rate',
            component: Rate,
            meta: {
              childComponent: true
            }
          },
          {
            path: 'report',
            name: 'Report',
            component: Report,
            meta: {
              childComponent: true
            }
          },
          {
            path: 'profil',
            name: 'Profil',
            component: Profil,
            meta: {
              childComponent: true
            }
          }
        ]
      },
      {
        path: 'accessibility',
        name: 'Accessibility',
        component: Accessibility
      },
      {
        path: 'contact',
        name: 'Contact',
        component: Contact
      },
      {
        path: 'legal',
        name: 'Legal',
        component: Legal
      },
      {
        path: 'news',
        name: 'News',
        component: News
      },
      {
        path: 'notification',
        name: 'Notification',
        component: Notification
      },
      {
        path: 'profil',
        name: 'Profil',
        component: Profil
      },
      {
        path: 'subscription',
        name: 'Subscription',
        component: Subscription
      }
    ]
}

export default parametersRoute;