import api from '@/api';
import router from '@/router';
import hoseaInput from '@/components/Form/hoseaInput.vue';
import Button from '@/components/Form/Button.vue';
import Error from '@/components/Form/Error.vue';

export default {
  name: 'LoginView',
  components: {
    hoseaInput,
    Button,
    Error
  },
  data() {
    return {
      username:"",
      password:"",
      msg:[],
      errMsg:""
    };
  },
  watch: {
    username(value){
        this.username = value;
        this.validateEmail(value);
    },
    password(value){
        this.password = value;
        this.validatePassword(value);
      }
  },
  methods: {
    loginUser() {
        if (this.username.length > 0 && this.password.length >= 8) {
        const user = {
            username: this.username,
            password: this.password,
            };
        api.loginUser(user)
        .then(response => {
            const token = response.data.token;
            const user = response.data.user;
            this.$store.dispatch('setUser', user)
            this.$store.dispatch('setToken', token)

            // Save user information in local storage
            const userInfo = {
              token: token,
              user: user
            };
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
            router.push({
                name: 'Home',
              })
        })
        .catch(error => {
          if(error.response.data.non_field_errors && error.response.data.non_field_errors.length){
            this.errMsg = error.response.data.non_field_errors;
            this.errMsg = this.errMsg.join('')

          }
          else{
            this.errMsg = error.response.data
          }
        });
    } else {
        this.errMsg = 'Veuillez remplir tous les champs';
      }
    },
    validatePassword(value){
        if (value.length< 8) {
          this.msg['password'] = 'Doit contenir 8 caractères ';
        }
        else{
            this.msg['password']='';
        }
        if(value == '') {
           this.msg['password'] = '';
        }
      },
    validateEmail(value){
        if (/^\w+([.-]?\w+)@\w+([.-]?\w+)(.\w{2,3})+$/.test(value))    {
            this.msg['username'] = '';
            } else if(value == ''){
                this.msg['username'] = '';
            }
                else{
                    this.msg['username'] = 'Adresse électronique non valide';

                }
      }
  }
};
