<section class="flex mt-4 sm:mt-24 flex-col-reverse justify-around p-8 sm:p-4 items-center">
  <div class="container">

    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:px-12 lg:px-0">

        <div id="personalizedProgramCard" class="max-h-full rounded-b-lg rounded-t-lg bg-red-600 shadow">
          <div class="bg-white border-white rounded-t-lg shadow">
              <!-- Date -->
              <h2 class="text-md text-left p-2 font-bold tracking-tight ">Mardi 13</h2>
          </div>
          <div class="p-4 text-white rounded-lg md:p-8" id="services" role="tabpanel" aria-labelledby="services-tab">
              <div class="flex flex-row items-center justify-between">
                <h2 class="mb-5 text-md text-left font-extrabold tracking-tight ">Programme personnalisé</h2>
                <h2 class="mb-5 text-xs font-extrabold tracking-tight ">10 - 13 min</h2>
              </div>
              <!-- Lorem ipsum -->
              <p class="mb-4 text-xs text-justify ">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl nec tincidunt lacinia, tortor nisl aliquam mauris, et ultricies nisl nunc eget lorem. Sed euismod, nisl nec tincidunt lacinia, tortor nisl aliquam mauris, et ultricies nisl nunc eget lorem.</p>
              <p class="text-left ">Apitudes</p>
          </div>
          <div class="flex flex-row items-center justify-between">
            <div class="flex flex-row">
              <Button class="h-8 w-8 m-1" shape="circle" variant="causeaeffet" size="xs" borderStyle="home">
                <!-- <font-awesome-icon icon="minus" /> -->
              </Button>
              <Button class="h-8 w-8 m-1" shape="circle" variant="coordinationoeilmain" size="xs" borderStyle="home">
                <!-- <font-awesome-icon icon="minus" /> -->
              </Button>
              <Button class="h-8 w-8 m-1" shape="circle" variant="equilibre" size="xs" borderStyle="home">
                <!-- <font-awesome-icon icon="minus" /> -->
              </Button>
              <Button class="h-8 w-8 m-1" shape="circle" variant="memoire" size="xs" borderStyle="home">
                <!-- <font-awesome-icon icon="minus" /> -->
              </Button>
              <Button class="h-8 w-8 m-1" shape="circle" variant="rapidite" size="xs" borderStyle="home">
                <!-- <font-awesome-icon icon="minus" /> -->
              </Button>

            </div>
          </div>
          <div class="p-4 mt-8 bg-red-400 border-t-2 border-red-200 text-white rounded-b-lg">
            <!-- Commencer le programme text-->
            <div class="flex flex-row items-center justify-end font-bold">
              <p class="text-xs text-right mr-4">Commencer le programme</p>
              <svg class="text-black group-hover:text-gray-700 transition-colors duration-300" width="12" height="12" fill="none" viewBox="0 0 24 24" stroke="white">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M9 5l7 7-7 7"></path>
              </svg>
              </div>
          </div>

        </div>
    <div class="block lg:grid grid-cols-2 gap-4">

        <div id="infoCard" class="text-white max-h-full bg-blue-600 rounded-md shadow col-span-2" >
          <div class="p-4 md:p-8" id="services" role="tabpanel" aria-labelledby="services-tab">
              <div class="flex flex-row items-center justify-between">
                <h2 class="mb-5 text-3xl text-left font-extrabold tracking-tight ">Infos</h2>
              </div>
            </div>
            <div class="flex flex-row items-center justify-center mt-auto">
                <Button class="h-6 w-6 m-1" shape="circle" variant="causeaeffet" size="xs" borderStyle="home">
                  <!-- <font-awesome-icon icon="minus" /> -->
                </Button>
                <Button class="h-6 w-6 m-1" shape="circle" variant="coordinationoeilmain" size="xs" borderStyle="home">
                  <!-- <font-awesome-icon icon="minus" /> -->
                </Button>
                <Button class="h-6 w-6 m-1" shape="circle" variant="equilibre" size="xs" borderStyle="home">
                  <!-- <font-awesome-icon icon="minus" /> -->
                </Button>
            </div>
        </div>

        <div id="autonomySimulatorCard" class="text-white max-h-full bg-gray-600 rounded-md shadow mt-8 lg:mt-0" >
            <div class="p-4 md:p-8" id="services" role="tabpanel" aria-labelledby="services-tab">
                <div class="flex flex-row items-center justify-between">
                  <h2 class="mb-5 text-3xl text-left font-extrabold tracking-tight ">Simulateur autonomie</h2>
                </div>
              </div>
              <div class="text-right">
                <Button class="h-10 w-10 m-1" shape="circle" variant="white" size="xs" borderStyle="home">
                  <svg class="text-black ml-2 group-hover:text-gray-700 transition-colors duration-300" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="black">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M9 5l7 7-7 7"></path>
                  </svg>
                </Button>
              </div>
        </div>
        <div id="rvSimulatorCard" class="text-white max-h-full bg-gray-600 rounded-md shadow mt-8 lg:mt-0" >
            <div class="p-4 md:p-8" id="services" role="tabpanel" aria-labelledby="services-tab">
                <div class="flex flex-row items-center justify-between">
                  <h2 class="mb-5 text-3xl text-left font-extrabold tracking-tight ">Simulateur RV</h2>
                </div>
              </div>
              <div class="text-right">
                <Button class="h-10 w-10 m-1" shape="circle" variant="white" size="xs" borderStyle="home">
                  <svg class="text-black ml-2 group-hover:text-gray-700 transition-colors duration-300" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="black">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M9 5l7 7-7 7"></path>
                  </svg>
                </Button>
              </div>
        </div>
    </div>
    </div>
  </div>
</section>
