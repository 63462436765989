<template>
  <Button v-for="parameter in tableList" :key="parameter.path" class="text-left h-18" borderStyle="parameters" variant="white" shape="square" size="lg" @click="handleButtonClick(parameter)">
    <div class="flex flex-row justify-between items-center">
      <label>
        <font-awesome-icon :icon="parameter.icon" class="text-black text-2xl mr-4" /> {{ parameter.name }}
      </label>
      <svg class="text-gray-500 group-hover:text-gray-700 transition-colors duration-300" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="grey">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M9 5l7 7-7 7"></path>
      </svg>
    </div>
  </Button>
</template>

<script>
import Button from '@/components/Form/Button.vue';
import router from '@/router';
export default {
  name: 'TableList',
  components: {
    Button,
  },
  props: {
    tableList: {
      type: Array,
      required: true,
    },
  },
  emits: ['parameter-clicked'], // Declare the emitted event
  methods: {
    handleButtonClick(parameter) {
      router.push({ name: parameter.path });
      this.$emit('parameter-clicked', parameter);
    },
  },
};
</script>

