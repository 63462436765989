<section class="flex flex-col-reverse sm:flex-row justify-around p-6 items-center">
    <div class="container flex flex-col sm:flex-row items-center justify-evenly px-5 mx-auto my-8">
        <div class="w-1/2 sm:block hidden">
            <img src="@/assets/logo_dummy.png" alt="" class="h-64"/>
        </div>
        <div class="sm:w-1/2">
            <form class="text-left">
                <hoseaInput id="password" name="password" v-model="password" label="Mot de passe" />
                <div class="my-6"></div>
                <hoseaInput id="confirmPassword" name="confirmPassword" v-model="confirmPassword" label="Confirmation du mot de passe" />

            </form>


            <div class="flex items-center flex-col sm:flex-row justify-start">
                <Button variant="primary" size="lg">Valider mot de passe</Button>
                <router-link to="/login" class="p-5 text-gray-700 underline hover:text-blue-700 ml-2">Connexion</router-link>
                <router-link to="#" class="p-5 text-gray-700 underline hover:text-blue-700 ml-2">Créer un compte</router-link>
            </div>
        </div>
    </div>
</section>
