
<section class="flex min-h-screen flex-col-reverse sm:flex-row justify-around p-6 items-center">
    <div class="container flex flex-col sm:flex-row items-center justify-evenly px-5 mx-auto my-8">
        <div class="sm:w-1/2 sm:block content-center">
            <img src="@/assets/svg/logo-hosea.svg" alt="" class=" h-48 sm:h-64 mx-auto" />
        </div>
        <div class="sm:w-5/6">
            <Error :errorMsg="msg.username" variant="large"></Error>
            <form class="text-center sm:text-left text-xl">
                <hoseaInput id="email" name="username" label="Adresse email" v-model="username" />
                <div class="my-6"></div>

                <Error :errorMsg="msg.password"></Error>
                <hoseaInput id="password" name="password" type="password" label="Mot de passe" autocomplete="current-password" v-model="password" />
            </form>
            <Error :errorMsg="errMsg" variant="large" />


            <div class="flex flex-col sm:flex-row items-center justify-between mt-8 sm:mt-0">
                <Button class="mb-8 sm:mb-0" variant="primary" size="lg" @click="loginUser">Connexion</Button>
                <div class="flex flex-col sm:flex-row">
                    <router-link to="/register" class="my-2 sm:my-0 text-gray-700 underline hover:text-blue-700 ml-2">Créer un compte</router-link>
                    <router-link to="/reset-password" class="my-2 sm:my-0 text-gray-700 underline hover:text-blue-700 ml-2">Compte perdu</router-link>

                </div>
            </div>
        </div>
    </div>
</section>
