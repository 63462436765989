<div>
    <Details
    headerBgColor="bg-red-700"
    imageSrc="pexels-pixabay-206959.jpg"
    :title="game.title"
    type="Food"
    :content="game.description"
    :scienceFact="game.scienceFact"
    bestScoreLabel="Best Score"
    bestScore="500"
    currentLevelLabel="Current Level"
    currentLevel="5"
    difficultyLabel="Difficulty"
    difficulty="Medium"
    levelLabel="Level"
    :level="level"
    @updateLevel="updateLevel"
    @playGame="playGame"
  />
</div>